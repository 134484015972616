import { AxiosRequestConfig } from 'axios';

export const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_FORMAT_JAPAN = 'YYYY年MM月DD日 ';
export const DATE_TIME_HOUR_SECOND = 'YYYY/MM/DD HH:mm:ss';
export const DATE_TIME_HOUR_MIN = 'YYYY/MM/DD HH:mm';
export const DATE_TIME_HOUR = 'YYYY-MM-DD HH:mm';
export const HOUR_MIN = 'HH:mm';
export const MONTH_YEAR = 'MM/YYYY';
export const YEAR_MONTH = 'YYYYMM';
export const MAX_PAGE_SIZE_POPUP = 5;

const apiVersion = 'v1';
const apiService = 'admin';

export const config: AxiosRequestConfig = {
  baseURL:
    `${process.env.REACT_APP_BASE_API_URL}/${apiVersion}/${apiService}` ||
    'http://localhost:3000',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const configBatch: AxiosRequestConfig = {
  baseURL:
    `${process.env.REACT_APP_BASE_API_BATCH_URL}/${apiVersion}/${apiService}` ||
    'http://localhost:3000',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const ADMIN_ROLES = {
  systemAdmin: 'ROLE_ADMIN',
  viewAdmin: 'ROLE_ADMIN_VIEW',
};

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'ec_site_admin_access_token',
  REFRESH_TOKEN: 'ec_site_admin_refresh_token',
  AUTH_ME_INFO: 'ec_admin_site_me_info',
  LANG: 'ec_admin_site_lang',
  SITE_INFO: 'ec_admin_site_info',
  USER_ROLES: 'ec_admin_user_roles',
};

export const PER = {
  DEFAULT: 10,
  USER: 10,
};

export const MACHINING_METHOD = {
  EXCLUSION: ['KK', 'KP', 'FK', 'FP'],
  Z0: 'Z0',
  H2TOH5: ['H2', 'H3', 'H4', 'H5'],
};

export const fileTypes = ['image/png', 'image/jpeg'];
export const fileSize = 10; //Mb

export enum APP_ACTIONS {
  CHANGE_LANG = 'change_lang',
  UPDATE_MOUNTED = 'update_mounted',
  UPDATE_IS_FETCHING = 'update_is_fetching',
}

export const STATUS_CODE = {
  EMAIL_NOT_EXIST: 'E000032',
  PASSWORD_INVALID: 'E000033',
  FORGOT_PASS_EMAIL_NOT_EXIST: 'E000032',
  FORGOT_PASS_SEND_OTP_TIMEOUT: 'EC-1301',
  FORGOT_PASS_SEND_DUPLICATE_DATA: 'E000060',
  FORGOT_PASS_SEND_WRONG_LANG: 'EC-1117',
  RESET_PASS_EMAIL_NOT_EXIST: 'EC-0401',
  RESET_PASS_WRONG_OTP: 'E000041',
  RESET_PASS_OTP: 'E000041',
  RESET_PASS_OTP_EXPIRED: 'EC-1110',
  USER_LOCKED: 'EC-423',
  EMAIL_ALREADY_USED: 'E000072',
  FILE_SIZE_EXCESS_IN_5MB: 'E000028',
  FILE_COPY_FAILED: 'E000059',
  DUPLICATE_DATA: 'E000060',
  ALREADY_USED: 'E000055',
  NOT_YET_CURRENCY_TRANSFERS: 'E000067',
  DUPLICATE_API_SEQNO: 'E000090',
  OBJECT_NOT_FOUND: 'EC-0500',
};

export const STATUS_FIELD = {
  TYPE_SHAPE: 'hinshL3CdAndKeijoL3Cd',
  PRODUCT_METHOD: 'setdnKakoCdAndShhnCd',
};

export const REGEX = {
  REGEX_NUMBER: /(.*\d.*)/,
  REGEX_UPPER_CASE: /(.*[A-Z].*)/,
  REGEX_LOWER_CASE: /(.*[a-z].*)/,
  REGEX_SPECIAL_CHARACTER: /(.*\W.*)/,
  REGEX_PASSWORD_MATCHES: /^[a-zA-Z0-9!@#$%^&*()_=;'?{}~<>|[\]\\-`.+,/\\"]*$/,
  REGEX_EMAIL_MATCHES:
    /^[a-zA-Z0-9](([\\.]|[\\+]|[\\-]|[\\_])?[a-zA-Z0-9])+@[a-zA-Z0-9]+([\\.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,3})$/,
  REGEX_CHARACTER_CKEDITOR: /<\s*[^>]*>/gi,
  REGEX_PHONE_NUMBER: /^[0-9-]*$/,
  REGEX_HOUR_MIN: /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]$))/,
  REGEX_0_TO_99: /^\d{1,2}(?:[.,]\d{1})?$/,
  REGEX_ONLY_NUMBER: /^[0-9]*(?:\.[0-9]*)?$/,
  REGEX_NEGATIVE_NUMBER: /^[-]?[0-9]+(?:\.[0-9]*)?$/,
  REGEX_ONLY_INTEGER_NUMBER: /^-?\d*?$/,
  REGEX_ONLY_HALFWIDTH_CHARACTERS:
    /^[a-zA-Z0-9\-!@#$%^&*()_=;'?{}~<>|[\]\\`.+,/\\"]*$/,
  REGEX_0_TO_99999999999999: /^\d{1,14}(?:[.,]\d{1,14})?$/,
  REGEX_0_TO_999999999: /^\d{1,9}(?:[.,]\d{1,9})?$/,
  REGEX_1_TO_9999999: /^(?!(0))\d{1,7}$/,
  REGEX_PERCENT: /^(-?((?!(0))\d{1,2}(?:[.]\d{1})?|\d{1,1}(.)\d{1}))$/,
  REGEX_PROFIT_RATE:
    /^(?!-?0(\.0{1,3})?$)(-?[0-9]{1,7}(\.[0-9]{1,3})?|9999999(\.999)?)$/,
  REGEX_DIMENSIONS:
    /^(\d+)?x?((\d+)|(\d+)x)(\d+)?$|^(\d+)?X?((\d+)|(\d+)X)(\d+)?$|^(\d+)?[*]?((\d+)|(\d+)[*])(\d+)?$/,
  REGEX_NUMERIC_CHARACTER: /[0-9.]/g,
};

export const STATUS_MASTER_REGISTRATION = {
  TIMER: '01',
  RESERVATION: '02',
};

export const PROCESSING_METHOD_CODE = {
  STANDARD: 'C',
  LENGTH: 'I',
  STANDARD_LENGTH: 'Z0',
};

export const STATUS_UPLOAD_CSV = {
  ERROR: '01',
  UPLOADED: '02',
  RUNNING: '03',
  FAILURE: '04',
  SUCCESS: '05',
  WAITING_REFLECTION: '06',
};

export const FLAG_PLUGIN = {
  OPERATION: 0,
  STOP: 1,
};

export const ENV = {
  FRONTSITE_URL: process.env.REACT_APP_FRONTSITE_URL,
};

export const STATUS_PLUGIN = {
  CALENDAR: '1',
  CHAT: '2',
  FAQ: '3',
  QR_CODE: '4',
};

export const EXIST_PLUGIN = '1';

export const LOCK_FLAG = {
  UNLOCK: '0',
  LOCK: '1',
};

export const INIT_PASS_SEND_FLAG = {
  TRUE: '0',
  FALSE: '1',
};

export enum NOKI_EXTERNAL {
  NOKI_WATERWAY = 'shipping.surface',
  NOKI_AIRLINE = 'shipping.airplane',
}
export const POST_FLAG_PRIVATE = '0';

export const DELIVERY_NUMBER_DEFAULT = '001';

export const DISPLAY_OF_TOTAL_AMOUNT = {
  TAX_EXCLUDED: 'TAX_EXCLUDE',
  TAX_INCLUDED: 'TAX_INCLUDE',
};

export const CATEGORY_PDF_REGISTER_CODE = {
  TERMS_OF_SERVICE: '01',
  FOR_THE_FIRST_TIME_USERS: '02',
  ELECTRONIC_CATALOG: '03',
};

export const LANGUAGE_CODE = {
  JAPAN: 'ja',
  ENGLISH: 'en',
};

export enum CATEGORIES_ID {
  ALUMNINUM = '01',
  COPPER = '02',
  STAINLESS_STEEL = '03',
  STEEL = '04',
  OTHERS = '05',
  PLASTIC = '06',
  TOOL_PRODUCTS = '07',
}

export enum SHAPE {
  PLATE = '01',
  BAR = '02',
  PIPE = '03',
}

export enum LABEL {
  QUOTATION = '01',
  TRIAL = '02',
  ORDER = '03',
}
